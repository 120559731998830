import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Box from "../../components/Box"
import Text from "../../components/Text"
import Heading from "../../components/Heading"
import Container from "../../components/UI/Container"
import GlideCarousel from "../../components/GlideCarousel"
import GlideSlide from "../../components/GlideCarousel/glideSlide"
import TestimonialSecWrapper, { TestimonialItem } from "./bannerSlider.style"

const TestimonialSection = ({
  reviewStyle,
  TestimonialMeta,
  nameStyle,
  designationStyle,
}) => {
  //Carousel Options
  const carouselOptions = {
    type: "carousel",
    autoplay: 3000,
    perView: 1,
    animationDuration: 600,
  }

  const data = useStaticQuery(graphql`
    {
      allPrismicTestimonio {
        edges {
          node {
            data {
              nombre {
                text
              }
              texto {
                text
              }
              titulo {
                text
              }
            }
          }
        }
        totalCount
      }
    }
  `)

  const testimonios = data.allPrismicTestimonio.edges

  return (
    <TestimonialSecWrapper id="testimonial_section">
      <Container>
        <div style={{ width: "440px" }}>
          <GlideCarousel
            options={carouselOptions}
            bullets={true}
            numberOfBullets={data.allPrismicTestimonio.totalCount}
            controls={false}
          >
            <>
              {testimonios.map((slideItem, index) => (
                <GlideSlide key={`testimonial-slide-${index}`}>
                  <TestimonialItem className="testimonial_item">
                    <Text
                      content={slideItem.node.data.texto.text}
                      {...reviewStyle}
                    />
                    <Box {...TestimonialMeta}>
                      <Box>
                        <Heading
                          content={slideItem.node.data.nombre.text}
                          {...nameStyle}
                        />
                        <Text
                          content={slideItem.node.data.titulo.text}
                          {...designationStyle}
                        />
                      </Box>
                    </Box>
                  </TestimonialItem>
                </GlideSlide>
              ))}
            </>
          </GlideCarousel>
        </div>
      </Container>
    </TestimonialSecWrapper>
  )
}

TestimonialSection.propTypes = {
  reviewStyle: PropTypes.object,
  TestimonialMeta: PropTypes.object,
  nameStyle: PropTypes.object,
  designationStyle: PropTypes.object,
}

TestimonialSection.defaultProps = {
  reviewStyle: {
    fontSize: ["16px", "16px", "16px", "16px", "16px"],
    fontWeight: "400",
    color: "rgb(82, 95, 127)",
    lineHeight: "1.74",
    mb: ["30px", "30px", "30px", "30px", "40px"],
  },
  TestimonialMeta: {
    flexBox: true,
    alignItems: "center",
  },
  nameStyle: {
    as: "h3",
    fontSize: ["16px", "17px", "18px", "18px", "20px"],
    fontWeight: "500",
    color: "rgb(50, 50, 93)",
    mb: "5px",
    fontFamily: "Poppins",
  },
  designationStyle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "rgb(82, 95, 127)",
    mb: "0",
    fontFamily: "Poppins",
  },
}

export default TestimonialSection
