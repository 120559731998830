const colors = {
  transparent: "transparent", // 0
  black: "#000000", // 1
  white: "#ffffff", // 2
  headingColor: "#0f2137",
  textColor: "#2653AF", // 3
  labelColor: "#232323", // 4
  inactiveField: "#f2f2f2", // 5
  inactiveButton: "#b7dbdd", // 6
  inactiveIcon: "#EBEBEB", // 7
  primary: "#18288D", // 8
  primaryHover: "#2653AF", // 9
  secondary: "#FFC13D", // 10
  secondaryHover: "#FCD183", // 11
  yellow: "#FFC13D", // 12
  yellowHover: "#FCD183", // 13
  primaryBoxShadow: " -6.691px 7.431px 20px 0px rgba(74, 47, 177, 0.2) ",
}

export default colors
