import React from "react"
import PropTypes from "prop-types"
import Box from "../../components/Box"
import Text from "../../components/Text"

import Heading from "../../components/Heading"
import FeatureBlock from "../../components/FeatureBlock"
import Container from "../../components/UI/Container"
import { BetaSectionWrapper } from "./betaSection.style"

const BetaSection = ({
  row,
  title,
  description,
  featureTitleStyle,
  featureDescriptionStyle,
}) => {
  return (
    <BetaSectionWrapper id="sobremi">
      <Container noGutter mobileGutter>
        <Box className="row" {...row}>
          <FeatureBlock
            title={<Heading {...title} />}
            description={<Text {...description} />}
          />
        </Box>
      </Container>
    </BetaSectionWrapper>
  )
}

// Transactions style props
BetaSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  row: PropTypes.object,
  featureTitleStyle: PropTypes.object,
  featureDescriptionStyle: PropTypes.object,
}

// Trusted default style
BetaSection.defaultProps = {
  // Trusted section row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "center",
  },

  title: {
    content: "Luciana Díaz Pastor",
    fontSize: ["24px", "26px", "30px", "36px", "40px"],
    lineHeight: ["30px", "32px", "40px", "50px", "55px"],
    fontWeight: "700",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "20px",
    textAlign: ["center", "center"],
  },

  description: {
    content:
      "Luciana nació en Lima en el 2008, estudia en el Colegio de la Inmaculada. Desde muy pequeña le interesaron las letras y también desde muy pequeña mostró empatía por todo a su alrededor. Desde una hormiga hasta personas al otro lado del mundo. Para Luciana es muy importante la equidad y cree firmemente que nuestras diferencias nos hacen únicos y que todas estas deben ser respetadas. Es por eso que hoy nos trae su primera creación literaria: La cebra con rayas doradas que nos deja un mensaje importante sobre respeto, tolerancia y amor propio.",
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "28px",
    mb: ["25px", "25px", "30px", "30px", "45px"],
    textAlign: ["center", "center"],
  },
  featureTitleStyle: {
    fontSize: ["18px", "18px", "20px", "20px", "20px"],
    lineHeight: ["1", "1", "1", "1", "1"],
    fontWeight: "500",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "10px",
    textAlign: ["left", "left"],
  },
  // Trusted section description default style
  featureDescriptionStyle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "27px",
    textAlign: ["left", "left"],
  },
}

export default BetaSection
