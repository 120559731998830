import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from "../../components/UI/Container"
import { GatsbyImage } from "gatsby-plugin-image"
import GalleryArea, { Row, Col } from "./gallery.style"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "image/gallery" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  const fotos = data.allFile.edges
  return (
    <GalleryArea>
      <Container className="Container">
        <Row>
          {fotos.map(image => (
            <Col key={`gallery-key-${image.node.id}`}>
              <GatsbyImage
                image={image.node.childImageSharp.gatsbyImageData}
                alt="gallery image"
              />
            </Col>
          ))}
        </Row>
      </Container>
    </GalleryArea>
  )
}

export default Gallery
