import React from "react"
import PropTypes from "prop-types"
import Box from "../../components/Box"
import Image from "../../components/Image"
import Text from "../../components/Text"
import Fade from "react-reveal/Fade"
import Heading from "../../components/Heading"
import Container from "../../components/UI/Container"
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
  FeatureSection,
} from "./promotionBlock.style"
import Foto from "../../assets/image/promotion.svg"
import { data } from "./data"

const PromotionBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="precio">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading as="h5" content="Precio" />
              <Heading content="S/.35.00" />
              <Text content="El 25% del valor de este cuento será destinado a los objetivos sociales del Programa de Educación Básica Laboral PEBAL del colegio La Inmaculada" />
              <a
                href="https://pebalsj.org/"
                target="_blank"
                rel="noreferrer"
                className="learn__more-btn"
              >
                <span className="hyphen" />
                <span className="btn_text">Conoce más sobre PEBAL</span>
              </a>
            </ContentWrapper>
            <FeatureSection>
              <span className="btn_text">Auspiciado por:</span>
              {data.map((item, index) => (
                <Fade up key={`feature-${index}`}>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <div className="featureWrapper">
                      <Image src={item.logo} alt={item.id} />
                    </div>
                  </a>
                </Fade>
              ))}
            </FeatureSection>
          </Box>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Image src={Foto} alt="Charity Landing" />
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  )
}

// PromotionBlock style props
PromotionBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
}

// PromotionBlock default style
PromotionBlock.defaultProps = {
  // PromotionBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // PromotionBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
}

export default PromotionBlock
