import logoRuway from "../../assets/image/ruway-logotipo.png"
import logoAndes from "../../assets/image/logo-andes-original.png"

export const data = [
  {
    id: "ruway",
    logo: logoRuway,
    link: "https://www.ruwaysolutions.com/",
  },
  {
    id: "andes",
    logo: logoAndes,
    link: "http://www.andestechnology.com/",
  },
]
