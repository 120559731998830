import React, { Fragment } from "react"
import Sticky from "react-stickynode"
import { ThemeProvider } from "styled-components"
import { cryptoTheme } from "../theme"
import { ResetCSS } from "../assets/css/style"
import {
  GlobalStyle,
  ContentWrapper,
} from "../containers/lucianaluciernaga.style"
import { DrawerProvider } from "../contexts/DrawerContext"
import Navbar from "../containers/Navbar"
import Banner from "../containers/BannerSection"
import BannerSlider from "../containers/BannerSlider"
import Transactions from "../containers/Transaction"
import Gallery from "../containers/Gallery"
import BetaSections from "../containers/BetaSection"
import PromotionBlock from "../containers/PromotionBlock"
import FeatureSlider from "../containers/CryptoSlides"
import Footer from "../containers/Footer"
import Seo from "../components/seo"

const IndexPage = () => {
  return (
    <ThemeProvider theme={cryptoTheme}>
      <Fragment>
        <Seo title="Luciana Luciernaga" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner />
          <BannerSlider />
          <Transactions />
          <FeatureSlider />
          <Gallery />
          <BetaSections />
          <PromotionBlock />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  )
}

export default IndexPage
